<script>
import Debug from '@/debugger'
import DocumentEditor from '@/mixins/document-editor'
import IncExc from '@/pages/search/controllers/inc-exc'
import { GlobalSearch } from '@/pages/search/controllers'
import sidePanelController from '@/sidePanelController'
const debug = Debug('cot:side-panel:preset')

export default {
  inheritAttrs: false,
  CacheType: DocumentEditor.CacheType,
  mixins: [DocumentEditor],
  activated () {
    this.registerWatchers()
  },
  beforeDestroy () {
    this.unregisterWatchers?.()
  },
  deactivated () {
    this.unregisterWatchers?.()
  },
  mounted () {
    this.$once('document-saved', () => { this.hasBeenSaved = true })
    this.registerWatchers()
    this.refreshDocument()
  },
  data () {
    return {
      id: this.documentId,
      form: false,
      hasBeenSaved: false,
      required: [
        v => (!!v && !this.lodash.isEqual(v, {})) || this.$t('t.IsRequired')
      ],
      unwatchGlobalSearch: undefined,
      unwatchSidePanel: undefined
    }
  },
  computed: {
    actions () {
      const actions = []
      if (this.id) {
        actions.push({
          text: this.$t('t.Duplicate'),
          icon: 'i.Templates',
          callback: this.clone
        })
      }
      return actions.length ? actions : undefined
    },
    cameleonDocType () {
      const cameleonDocType = new IncExc()
      cameleonDocType.include([this.document.viewType])
      return cameleonDocType
    },
    canSave () {
      return this.form && this.document.columns?.length
    },
    preview () {
      return this.document?.isSystem
    },
    tab () {
      return this
    }
  },
  methods: {
    async addedColumn (id) {
      await this.$waitFor(() => this.isReady)
      if (!id) {
        this.$refs.columns[this.$refs.columns.length - 1].focus()
      }
    },
    async clone () {
      sidePanelController.displayDocument(
        undefined,
        sidePanelController.displayedDocument.type,
        {
          targetDocTy: this.params.targetDocTy,
          scope: this.params.scope,
          columns: this.document.columns
        }
      )
    },
    close () {
      this.$emit('replace-document')
    },
    closePicker (event) {
      this.$refs.columns[event.oldIndex].closeMenu()
    },
    registerWatchers () {
      this.watchSidePanel()
    },
    removeColumn (index) {
      this.document.columns.splice(index, 1)
      this.updateSetLive()
    },
    async revert () {
      this.refreshDocument()
    },
    async savePreset (type) {
      let request
      if (this.documentIsNew) {
        request = this.$http().post(`/core/v6/cameleon/${type}`, this.documentToJSON())
      } else {
        request = this.$http().put(`/core/v6/cameleon/${type}/${this.document.id}`, this.documentToJSON())
      }
      return await request.then(async (req) => {
        const id = req?.data?.id ?? this.id
        if (req?.data?.id) {
          this.$emit('replace-document', { id, params: this.params, type })
        }
        return id
      })
    },
    unregisterWatchers () {
      debug('Unwatch side panel')
      this.unwatchSidePanel?.()
      this.unwatchSidePanel = undefined
      this.unwatchGlobalSearch?.()
      this.unwatchGlobalSearch = undefined
    },
    validateForm () {
      this.$waitFor(() => this.$refs.form)
        .then(form => form?.validate() ?? false)
        .catch(e => {
          // Do not throw if the cache is falsy, that mean the user as already close the document
          if (this.cache) {
            throw e
          }
        })
    },
    watchSidePanel () {
      if (this.unwatchSidePanel) { return }
      debug('Watch side panel')
      this.unwatchSidePanel = this.$watch(
        () => sidePanelController.isSidePanelHidden,
        isHidden => {
          if (isHidden) {
            debug('Unwatch global search')
            this.unwatchGlobalSearch?.()
            this.unwatchGlobalSearch = undefined
          } else {
            this.watchGlobalSearch()
          }
        },
        { immediate: true }
      )
    },
    watchGlobalSearch () {
      if (this.unwatchGlobalSearch) { return }
      debug('Watch global search')
      this.unwatchGlobalSearch = this.$watch(
        () => GlobalSearch.getActiveGridSearch(this.params.scope, this.params.targetDocTy),
        search => {
          if (search) {
            this.updateSetLive()
          }
        },
        { immediate: true }
      )
    }
  },
  props: {
    documentId: String,
    params: Object
  }
}
</script>
